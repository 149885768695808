import { Notification } from '@/components/Modal/Notification';
import { decodeTokenAPI } from '@/services/Controllers/Token';
import { isMaster, isShopkeeper } from '@/services/User';
import _ from 'lodash';

export const formatToTable = routes => routes.map(route => ({
            ...route,
            key: route.idcdcrotafront,
            description: route.description ? route.description : '',
            status: STATUS[route.status ? route.status : false],
            hideinmenuText: route.hideinmenu ? 'NÃO' : 'SIM',
            component: route.component ? route.component : '',
            icon: route.icon ? route.icon : 'tool',
        }));

export const formatRouteToTable = route => ({
        ...route,
        key: route.idcdcrotafront,
        description: route.description ? route.description : '',
        status: STATUS[route.status ? route.status : false],
        component: route.component ? route.component : '',
        icon: route.icon ? route.icon : 'tool',
    });

const getRegisteredRoutesOnConfig = routes => {
    const registeredRoutes = _.intersectionWith(
        ROUTES,
        routes,
        (routeConfig, route) =>
            route.path == routeConfig.path &&
            route.description == routeConfig.description &&
            route.name == routeConfig.name &&
            route.status == STATUS[routeConfig.status ? routeConfig.status : false],
    );
    return registeredRoutes;
};

const getRegisteredRoutes = routes => {
    const registeredRoutes = _.intersectionWith(
        routes,
        ROUTES,
        (route, routeConfig) =>
            route.path == routeConfig.path &&
            route.description == routeConfig.description &&
            route.name == routeConfig.name &&
            route.status == STATUS[routeConfig.status ? routeConfig.status : false],
    );
    return registeredRoutes;
};

const getNoRegisteredRoutesOnConfig = registeredRoutes => {
    const noRegisteredRoutes = _.differenceWith(ROUTES, registeredRoutes, _.isEqual);
    return noRegisteredRoutes;
};

const getNoRegisteredRoutes = (registeredRoutes, routes) => {
    const noRegisteredRoutes = _.differenceWith(routes, registeredRoutes, _.isEqual);
    return noRegisteredRoutes;
};

const removeUserRoute = routes => routes.filter(elem => elem.idcdcrotafront != ID_CDC_ROTA_FRONT.USER_REGISTER);

export const checkNoRegisteredRoutes = routes => {
    const registeredRoutes = getRegisteredRoutesOnConfig(routes);
    const noRegisteredRoutes = _.filter(
        getNoRegisteredRoutesOnConfig(registeredRoutes),
        route => route.path,
    );
    return noRegisteredRoutes;
};

export const checkNoRegisteredRoutesOnConfig = routes => {
    const registeredRoutes = getRegisteredRoutes(routes);
    const noRegisteredRoutes = getNoRegisteredRoutes(registeredRoutes, routes);
    return noRegisteredRoutes;
};

export const formatRoutesToRequest = routes => {
    const routesToRequest = [];
    _.map(routes, route => {
        routesToRequest.push({
            idcdcrotafront: route.idcdcrotafront,
            description: route.description,
            name: route.name,
            path: route.path,
            hideinmenu: route.hideinmenu,
            status: route.status,
        });
    });
    return routesToRequest;
};

export const formatRouteToRequest = route => {
    if (!route.path.startsWith('/')) {
        route.path = _.padStart(route.path, _.size(route.path) + 1, '/');
    }
    if (!route.component.startsWith(route.component, '.')) {
        route.component = _.padStart(route.component, _.size(route.component) + 1, '.');
    }
    if (!route.component.startsWith('/', 1)) {
        const aux = _.trimStart(route.component, '.');
        route.component = _.padStart(aux, _.size(aux) + 2, './');
    }
};

export const getComponentNameFromInput = component => {
    let newComponentName = _.cloneDeep(component);
    if (_.startsWith(newComponentName, './')) {
        newComponentName = _.trimStart(newComponentName, './');
    } else if (_.startsWith(newComponentName, '.')) {
        newComponentName = _.trimStart(newComponentName, '.');
    } else if (_.startsWith(component, '/')) {
        newComponentName = _.trimStart(newComponentName, '/');
    }
    return newComponentName;
};

const getRoutesWithPath = routes => {
    const routesWithPath = _.filter(routes, route => route.path);
    return routesWithPath;
};

export const getRouteKeys = routes => {
    const keys = _.map(routes, 'key');
    return keys;
};

export const getRouteIdsCdcRotaFront = routes => {
    const idsCdcRotaFront = _.map(routes, 'idcdcrotafront');
    return idsCdcRotaFront;
};

export const getCustomInitialRoute = routes => {
    const initialRoute = _.filter(routes, route => route.initial)[0];
    return initialRoute;
};

export const getRoutesByKeys = (routes, keys) => {
    const routesFound = _.intersectionWith(routes, keys, (route, key) => route.key == key);
    return routesFound;
};

export const getRoutesByPath = (routes, routesPath) => {
    const routesFound = _.intersectionWith(
        routes,
        routesPath,
        (route, path) => route.path == path.path,
    );
    return routesFound;
};

export const sortRoutesByKeys = (routes, keys) => {
    const sortedArray = [];
    let aux = {};
    _.map(keys, key => {
        aux = _.find(routes, route => route.key == key);
        if (aux) {
            sortedArray.push(aux);
        }
    });
    return sortedArray;
};

export const checkUniqueIdCdcRotaFront = routes => {
    let routesWithSameKey = [];
    const routesWithPath = getRoutesWithPath(routes);
    const routesGroupedByKey = _.groupBy(routesWithPath, route => route.idcdcrotafront);

    routesWithSameKey = _.filter(routesGroupedByKey, route => route.length > 1);
    return routesWithSameKey[0] ? routesWithSameKey[0].length <= 1 : true;
};

export const checkUniquePath = routes => {
    let routesWithSamePath = [];
    const routesWithPath = getRoutesWithPath(routes);
    const routesGroupedByPath = _.groupBy(routesWithPath, route => route.path);

    routesWithSamePath = _.filter(routesGroupedByPath, route => route.length > 1);
    return routesWithSamePath[0] ? routesWithSamePath[0].length <= 1 : true;
};

export const notifyNotRegisteredRoutes = routes => {
    const noRegisteredRoutes = checkNoRegisteredRoutes(routes);
    if (noRegisteredRoutes.length > 0) {
        Notification('info', 'ATENÇÃO', 'Há rotas não cadastradas. Sincronize-as!', 6);
    }

    const noRegisteredRoutesOnConfig = checkNoRegisteredRoutesOnConfig(routes);
    _.map(noRegisteredRoutesOnConfig, route => {
        Notification(
            'info',
            'ATENÇÃO',
            `A rota ${route.path} está cadastrada no banco porém não está na config. Cadastre-a!`,
            6,
        );
    });
};

export const childrenRoutesFormat = (children, routesConfig) => {
    const childrenRoutesFiltered = _.intersectionWith(
        children,
        routesConfig,
        (child, routeConfig) => {
            child.hideInMenu = child.hideinmenu ? child.hideinmenu : false;
            return child.path == routeConfig.path;
        },
    );
    return childrenRoutesFiltered;
};

export const filterRouteByConfig = routes => {
    const routesFiltered = _.intersectionWith(routes, ROUTES, (route, routeConfig) => {
        if (
            route.children &&
            route.children.length > 0 &&
            routeConfig.routes &&
            routeConfig.routes.length > 0 &&
            route.path === routeConfig.path
        ) {
            route.children = childrenRoutesFormat(route.children, routeConfig.routes);
            delete route.component;
            return route.path == routeConfig.path;
        }
        route.hideInMenu = route.hideinmenu ? route.hideinmenu : false;
        return route.path == routeConfig.path;
    });
    return routesFiltered;
};

export const getRoutes = () => {
    const rotas = decodeTokenAPI().autoridade;
    let filteredRoutes = filterRouteByConfig(rotas);

    if (isShopkeeper() && !isMaster()) {
        filteredRoutes = removeUserRoute(filteredRoutes);
    }
    return filteredRoutes || [];
};

export const getRouteAuthorities = routes => {
    const idsCdcRotaFront = _.map(routes, 'authority');
    return idsCdcRotaFront;
};

/**
 * Retorna a primeira rota a ser executada após o login
 */
export const getInitialRoute = () => {
    const initialRoute = _.minBy(getRoutes(), 'order');
    if (initialRoute && initialRoute.children && initialRoute.children.length > 0) {
        return initialRoute.children[0];
    }
    return initialRoute || [];
};

export const getInitialRoutePath = () =>
    (getInitialRoute().path ? getInitialRoute().path : '/login');

/**
 * Retorna a primeira rota a ser executada após o login
 */
export const getAuthorities = () => getRouteAuthorities(getRoutes());

export const sortRoutesByOrder = routes => _.sortBy(routes, 'order');

export const getUniqueRoute = routes => _.uniqBy(routes, 'path');

export const formatRouteToMenu = routes => {
    const formatedRoutes = getUniqueRoute(routes);
    return formatedRoutes;
};
